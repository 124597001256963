<template>
  <div class="aboutuser3d">
    <div class="aboutuser3d_bg" v-if="Object.keys(basicData).length != 0">
      <img :src="basicData.basicImg" alt="" />
    </div>
    <div
      class="aboutuser3d_img"
      v-if="Object.keys(basicData).length != 0"
      :style="{
        backgroundImage: `url(${basicData.basicBg[3]})`
      }"
    >
      <div class="aboutuser3d_nav">
        <div class="nav_row">
          <div class="nav_row_img zz_all_img">
            <img :src="basicData.basicBg[0]" alt="" />
          </div>
          <div class="nav_row_text" v-html="basicData.basicHtml"></div>
        </div>
        <ul class="nav_web">
          <li
            v-for="(item, idx) in basicData.basicList"
            :key="idx"
            :style="{
              backgroundImage: `url(${
                idx % 2 === 0 ? basicData.basicBg[1] : basicData.basicBg[2]
              })`
            }"
          >
            <h2 :style="{ marginTop: idx % 2 === 0 ? '18vw' : '0' }">
              {{ item.key }}
            </h2>
            <p>{{ item.value }}</p>
          </li>
        </ul>
        <div class="nav_cooperate">
          <div class="nav_cooperate_head">
            <h2>{{ hzData.hzTitle }}</h2>
            <h4>{{ hzData.hzText }}</h4>
          </div>
          <div class="nav_cooperate_list zz_all_img">
            <img v-for="(item,i) in hzData.hzImg" :key="i" :src="item" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      basicData: {},
      hzData: {}
    }
  },
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.basicData = res.data.data.basic
          that.hzData = res.data.data.合作
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.aboutuser3d {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  .aboutuser3d_bg {
    width: 100%;
    height: auto;
    img {
      width: 100%;
    }
  }
  .aboutuser3d_img {
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .aboutuser3d_nav {
    padding: 80px 0;
    width: 80%;
    margin: 0 auto;
    .nav_row {
      padding: 80px;
      background-color: #1c2733;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .nav_row_img {
        width: 40%;
        border-radius: 5px;
        box-shadow: 0px 0px 12px rgba(7, 7, 7, 0.9901658189240226);
        overflow: hidden;
        img {
          width: 100%;
          height: max-content;
        }
      }
      .nav_row_text {
        width: 55%;
        line-height: 2;
        /deep/font {
          font-size: 30px !important;
        }
        /deep/div span {
          font-size: 12px !important;
        }
      }
    }
    .nav_web {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 7vw 0;
      li {
        box-sizing: border-box;
        min-height: 30px;
        position: relative;
        width: 16.66%;
        background-color: rgba(255, 255, 255, 0);
        background-repeat: no-repeat;
        background-position: center center;
        padding: 3vw 0;
        h2 {
          box-sizing: border-box;
          min-height: 30px;
          min-width: 30px;
          position: relative;
          text-align: center;
          font-size: 24px;
          font-weight: 600;
        }
        p {
          line-height: 1.5;
          text-indent: 2em;
          font-size: 12px;
          color: #979797;
        }
      }
    }
    .nav_cooperate {
      .nav_cooperate_head{
        text-align: center;
        font-size: 30px;
        h2{
            font-weight: 600;
        }
        h4{
            font-size: 14px;
            margin-top: 1.5vw;
            line-height: 1.5;
        }
      }
      .nav_cooperate_list{
        margin-top: 3vw;
        img{
            width: calc(20% - 2vw);
            padding: 1vw;
        }
      }
    }
  }
}
</style>
