var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutuser3d"},[(Object.keys(_vm.basicData).length != 0)?_c('div',{staticClass:"aboutuser3d_bg"},[_c('img',{attrs:{"src":_vm.basicData.basicImg,"alt":""}})]):_vm._e(),(Object.keys(_vm.basicData).length != 0)?_c('div',{staticClass:"aboutuser3d_img",style:({
      backgroundImage: `url(${_vm.basicData.basicBg[3]})`
    })},[_c('div',{staticClass:"aboutuser3d_nav"},[_c('div',{staticClass:"nav_row"},[_c('div',{staticClass:"nav_row_img zz_all_img"},[_c('img',{attrs:{"src":_vm.basicData.basicBg[0],"alt":""}})]),_c('div',{staticClass:"nav_row_text",domProps:{"innerHTML":_vm._s(_vm.basicData.basicHtml)}})]),_c('ul',{staticClass:"nav_web"},_vm._l((_vm.basicData.basicList),function(item,idx){return _c('li',{key:idx,style:({
            backgroundImage: `url(${
              idx % 2 === 0 ? _vm.basicData.basicBg[1] : _vm.basicData.basicBg[2]
            })`
          })},[_c('h2',{style:({ marginTop: idx % 2 === 0 ? '18vw' : '0' })},[_vm._v(" "+_vm._s(item.key)+" ")]),_c('p',[_vm._v(_vm._s(item.value))])])}),0),_c('div',{staticClass:"nav_cooperate"},[_c('div',{staticClass:"nav_cooperate_head"},[_c('h2',[_vm._v(_vm._s(_vm.hzData.hzTitle))]),_c('h4',[_vm._v(_vm._s(_vm.hzData.hzText))])]),_c('div',{staticClass:"nav_cooperate_list zz_all_img"},_vm._l((_vm.hzData.hzImg),function(item,i){return _c('img',{key:i,attrs:{"src":item,"alt":""}})}),0)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }